import * as React from "react"
const Banner = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1080mm"
    height="640mm"
    viewBox="0 0 1080 640"
    {...props}
  >
    <text
      xmlSpace="preserve"
      x={540.045}
      y={409.89}
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: "225.778px",
        fontFamily: "sans-serif",
        InkscapeFontSpecification: "sans-serif",
        textAlign: "center",
        textAnchor: "middle",
        fill: "#4c9b7c",
        fillOpacity: 1,
        strokeWidth: 1.265,
      }}
    >
      <tspan
        x={540.045}
        y={409.89}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "225.778px",
          fontFamily: "Consolas",
          InkscapeFontSpecification: "Consolas",
          fill: "#4c9b7c",
          fillOpacity: 1,
          strokeWidth: 1.265,
        }}
      >
        {"elev"}
        <tspan
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontFamily: "Consolas",
            InkscapeFontSpecification: "Consolas",
            fill: "#f39744",
            fillOpacity: 1,
          }}
        >
          {"ai"}
        </tspan>
        {"te"}
      </tspan>
    </text>
    <text
      xmlSpace="preserve"
      x={537.6}
      y={496.913}
      style={{
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: "45.1556px",
        fontFamily: "Consolas",
        InkscapeFontSpecification: "Consolas",
        textAlign: "center",
        textAnchor: "middle",
        fill: "#f39744",
        fillOpacity: 1,
        strokeWidth: 1.265,
      }}
    >
      <tspan
        x={537.6}
        y={496.913}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "45.1556px",
          fontFamily: "Arial",
          InkscapeFontSpecification: "Arial",
          fill: "#000",
          strokeWidth: 1.265,
        }}
      >
        {"ELEVATE YOUR BUSINESS"}
      </tspan>
    </text>
  </svg>
)
export default Banner